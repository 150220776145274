import React, { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import extend from 'lodash/extend'
import { useSelector } from 'react-redux'

import SeverityGradient, { SeverityThreshold } from './SeverityGradient'
import { EditedRagReport, RagReport } from '../../services/rag'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'

// @ts-ignore
import deleteIcon from '../../lib/images/subtracting-button.svg'

interface Props {
  report: RagReport
  setNotes: (notes: string) => void
}

export default (props: Props) => {
  const { report } = props

  const { conditions }: ConsultationsState = useSelector(consultationsSelector)
  const [editedRagReport, setEditedRagReport] = useState<EditedRagReport | null>(null)

  useEffect(() => {
    const editedComponents: any = {}
    Object.keys(report.components).forEach((component) => {
      editedComponents[component] = extend({}, report.components[component], {
        selected: report.components[component].normal ? 'Normal' : 'Mild',
      })
    })
    setEditedRagReport({ combined_report: report.combined_report, components: editedComponents })
  }, [report])

  if (!editedRagReport) {
    return null
  }

  const updateSeverity = (mlName: string, severity?: SeverityThreshold) => {
    if (!severity) return

    const edited = cloneDeep(editedRagReport)
    edited.components[mlName].selected = severity
    setEditedRagReport(edited)
    let editedReport = props.report.combined_report

    Object.keys(edited.components).forEach((componentKey) => {
      const component = edited.components[componentKey]
      if (['Normal', 'Mild'].includes(component.selected)) return

      // @ts-ignore
      const initialText = component.normal || component.mild
      // @ts-ignore
      const editedText = component.selected === 'Absent' ? '' : component[component.selected.toLowerCase()]
      if (!initialText) return

      editedReport = editedReport.replace(initialText, editedText)
    })

    props.setNotes(`MUSCULOSKELETAL:\n\n${editedReport}`)
  }

  return (
    <div className="text-dark-bg mb-4">
      <p className="text-s mb-1">✨ Success! Adjust or remove severities below:</p>

      <div className="d-flex flex-wrap gap-20px">
        {Object.keys(editedRagReport.components).map((mlName, idx) => {
          const condition = conditions.find((condition) => mlName.replace(/_/g, ' ') === condition.ml_name || '')
          const severity = SeverityThreshold[editedRagReport.components[mlName].selected as keyof typeof SeverityThreshold]
          if (!condition || severity === SeverityThreshold.Absent) {
            return null
          }

          return (
            <div key={idx}>
              <div className="d-flex align-items-center mb-1">
                <p className="mb-0 text-m bold">{condition.display_name}</p>
                <img
                  onClick={() => updateSeverity(mlName, SeverityThreshold.Absent)}
                  className="icon-xxxs ml-2 pointer"
                  src={deleteIcon}
                />
              </div>

              <SeverityGradient threshold={severity} updateSeverity={(severity) => updateSeverity(mlName, severity)} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
