import React from 'react'

export enum SeverityThreshold {
  Absent = 'Absent',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
  Normal = 'Normal',
}

export const SEVERITIES = [SeverityThreshold.Mild, SeverityThreshold.Moderate, SeverityThreshold.Severe]

interface Props {
  threshold: SeverityThreshold
  updateSeverity: (severity: SeverityThreshold) => void
}

const marginForThreshold = (threshold: SeverityThreshold) => {
  if (threshold === SeverityThreshold.Severe || threshold === SeverityThreshold.Normal) {
    return 5
  } else if (threshold === SeverityThreshold.Mild) {
    return 125
  } else if (threshold === SeverityThreshold.Moderate) {
    return 65
  } else {
    return 175
  }
}

export default (props: Props) => {
  const margin = marginForThreshold(props.threshold)

  return props.threshold === SeverityThreshold.Normal ? (
    <div style={{ padding: '2px' }} className="width-200px healthy-bg position-relative rounded">
      <div
        className="position-absolute bottom-0"
        style={{
          left: `${margin}px`,
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderBottom: '16px solid white',
        }}
      />
      <p className="m-0 semibold text-m text-white ml-4">Normal</p>
    </div>
  ) : (
    <div style={{ padding: '2px' }} className={`width-200px ${props.threshold.toLowerCase()}-bg position-relative rounded pointer`}>
      <div style={{ marginRight: `${margin}px` }} className="position-relative transition-s">
        <p className="m-0 semibold text-m text-white text-right mr-4">{props.threshold}</p>

        <div
          className="position-absolute right-0"
          style={{
            bottom: '-2px',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '16px solid white',
          }}
        />
      </div>

      {SEVERITIES.map((severity) => {
        if (severity === props.threshold) return null

        return (
          <div
            onClick={() => props.updateSeverity(severity)}
            style={{ marginRight: `${marginForThreshold(severity)}px` }}
            className="position-relative"
          >
            <div
              className="position-absolute right-0 hover-severity-gradient-traingle transition-f"
              style={{
                bottom: '-2px',
                width: 0,
                height: 0,
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderBottom: '10px solid white',
                opacity: 0.3,
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
